import { RedoOutlined } from '@ant-design/icons';
import { Clipboard } from '@nextgis/utils';
import { Alert, Button, Collapse, Grid, Tooltip } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { appRoutes } from '../../routes/appRoutes';
import { toolBoxStore } from '../../store/store';
import { serializeErrorDescription } from '../../utils/orderUtils';

import { SupportButton } from './SupportButton';
import { OrderInputsInfo, OrderResults } from './orderSubComponents';

import type { Order } from '../../pages/Orders';

const getReuseParametersUrl = (order: Order) => {
  const toolUrl = `../${appRoutes.toolPage}/${order.parameters.operation_name}`;

  const inputs = Object.entries(order.parameters.inputs);
  const inputsPrefill = `?${inputs
    .map(([key, value]) => {
      if (typeof value === 'object') {
        return key + '=' + encodeURIComponent(value.name);
      } else {
        return key + '=' + encodeURIComponent(value);
      }
    })
    .join('&')}`;

  return toolUrl + inputsPrefill;
};

type StatusColor = {
  [key: string]: string;
};

const goodColor = 'rgb(107, 241, 199)';
const badColor = 'rgb(255, 204, 199)';

const colorMapping: StatusColor = {
  FAILED: badColor,
  DENIED: badColor,
  ACCEPTED: goodColor,
  STARTED: goodColor,
  SUCCESS: goodColor,
  CANCELLED: badColor,
};

const getColor = (status: string): string => {
  if (colorMapping[status]) {
    return colorMapping[status];
  }
  return 'lightgrey';
};

type StatusText = {
  [key: string]: string;
};

export const OrderDetails: React.FC<{ order: Order }> = ({ order }) => {
  const { t, i18n } = useTranslation();
  const { useBreakpoint } = Grid;
  const screens = useBreakpoint();
  const navigate = useNavigate();

  const handleReuseParameters = () => {
    navigate(getReuseParametersUrl(order));
  };

  const [copyText, setCopyText] = useState(t('orders.copyGUID'));

  const textMapping: StatusText = {
    FAILED: t('orders.status_texts.FAILED'),
    DENIED: t('orders.status_texts.DENIED'),
    ACCEPTED: t('orders.status_texts.ACCEPTED'),
    STARTED: t('orders.status_texts.STARTED'),
    SUCCESS: t('orders.status_texts.SUCCESS'),
    CANCELLED: t('orders.status_texts.CANCELLED'),
  };

  const getStatusText = (status: string): string => {
    if (textMapping[status]) {
      return textMapping[status];
    }
    return '';
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '6px',
          marginBottom: '10px',
          fontSize: 16,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'baseline',
            justifyContent: 'space-between',
          }}
        >
          <div style={{ marginBottom: '8px' }}>
            <span
              style={{
                lineHeight: 1.9,
                backgroundColor: getColor(order.status), // set custom colors
                padding: '2px 4px',
                borderRadius: '4px',
                marginRight: '4px',
              }}
            >
              {order.status}
            </span>
            {` `}
            <span>{getStatusText(order.status)}</span>
          </div>

          <div
            style={{
              display: 'flex',
              gap: '8px',
            }}
          >
            {toolBoxStore.appConfig.deploy === 'cloud' && (
              <Button
                href={`${appRoutes.toolPage}/${order.parameters.operation_name}?docs_open=1`}
                target="_blank"
              >
                {t('orders.docLink')}
              </Button>
            )}

            {order?.parameters?.inputs && (
              <a
                href={getReuseParametersUrl(order)}
                onClick={(e) => e.preventDefault()}
              >
                <Button
                  icon={
                    !screens.md && <RedoOutlined style={{ fontSize: '16px' }} />
                  }
                  onClick={handleReuseParameters}
                >
                  {screens.md && t('orders.reuseParameters')}
                </Button>
              </a>
            )}
            <SupportButton order={order} />
          </div>
        </div>
      </div>

      {order.status === 'SUCCESS' && <OrderResults order={order} />}

      {order.status === 'FAILED' && (
        <Alert
          type="error"
          message={
            <>
              <span>{t('orders.errorMessagePrefix')}</span>
              <br />
              {order.avral_error
                ? serializeErrorDescription(order.avral_error, i18n.language)
                : t('orders.undefinedError')}
            </>
          }
          description={t('orders.errorMessageLabelNew')}
          showIcon
          style={{ margin: '12px 0px', whiteSpace: 'pre-line' }}
        />
      )}
      {order?.parameters?.inputs && (
        <Collapse
          items={[
            {
              key: '1',
              label: t('orders.parameters'),
              children: <OrderInputsInfo order={order} />,
            },
          ]}
        />
      )}
      <div
        style={{
          textAlign: 'right',
          height: '8px',
        }}
      >
        <Tooltip title={copyText}>
          <Button
            type="link"
            style={{
              border: 0,
              padding: '0px 6px',
              height: '10px',
              color: 'rgb(158, 158, 158)',
              fontSize: '12px',
              cursor: 'pointer',
            }}
            onBlur={() => {
              setCopyText(t('orders.copyGUID'));
            }}
            onClick={() => {
              Clipboard.copy(order.guid);
              setTimeout(() => {
                setCopyText(t('orders.copyGUIDdone'));
              }, 100);
            }}
          >
            {order.guid}
          </Button>
        </Tooltip>
      </div>
    </>
  );
};
