import './pricing.css';

import { Button, Card, Grid } from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const { useBreakpoint } = Grid;

const PricingRu: React.FC = () => {
  const screens = useBreakpoint();

  return (
    <>
      <div
        style={{
          height: '100%',
        }}
      >
        <div
          style={{
            padding: '40px 24px 40px 12px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div>
            <h1 style={{ fontSize: 34, display: 'block', margin: 0 }}>Цены</h1>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                flexWrap: 'wrap',
              }}
            >
              <Card
                variant="borderless"
                style={{
                  maxWidth: '550px',
                  padding: '32px 32px 32px 0px',
                  boxShadow: 'none',
                }}
                styles={{
                  body: {
                    padding: 0,
                  },
                }}
              >
                <p style={{ marginTop: 0 }}>
                  Полный доступ к сервису NextGIS Toolbox входит в{' '}
                  <a href="https://nextgis.ru/pricing-base/">Premium</a>. На
                  этом плане вы можете запускать любой инструмент NextGIS
                  Toolbox неограниченное количество раз.{' '}
                </p>
                <p>Доступно на Premium:</p>
                <ul style={{ paddingLeft: '20px' }}>
                  <li>неограниченное количество запусков инструментов</li>
                  <li>2 ядра процессора</li>
                  <li>
                    16 GB оперативной памяти для запуска ресурсозатратных
                    инструментов и 1 GB - для остальных инструментов
                  </li>
                  <li>2 одновременно выполняемые задачи</li>
                </ul>
                <a href="https://nextgis.ru/pricing-base">
                  Подробнее о планах NextGIS
                </a>
              </Card>

              <Card
                style={{
                  position: 'relative',
                  maxWidth: '486px',
                  padding: '32px',
                  color: '#fff',
                  backgroundColor: '#0070c5',
                  boxShadow: '0 3px 8px rgba(0,0,0,.12)',
                }}
                styles={{
                  body: {
                    padding: 0,
                  },
                }}
              >
                <h3 style={{ marginTop: 0, letterSpacing: '2px' }}>PREMIUM</h3>

                <Card
                  className={
                    screens.sm ? 'pricing-sub-card' : 'pricing-sub-card__narrow'
                  }
                  style={{ height: '96px', width: '168px' }}
                  styles={{
                    body: {
                      paddingTop: '3px',
                      paddingLeft: '12px',
                    },
                  }}
                >
                  <span className="pricing-sub-card--number ">6 000</span>{' '}
                  Р/мес.
                  <br /> или
                  <br />{' '}
                  <span className="pricing-sub-card--number">54 000</span> Р/год
                </Card>

                <ul style={{ paddingLeft: '20px' }}>
                  <li>
                    Неограниченное количество
                    <br />
                    запусков инструментов
                    <br />
                    NextGIS Toolbox
                  </li>
                  <li>
                    Веб ГИС nextgis.com c неограниченным количеством слоев и
                    карт и возможностью командной работы
                  </li>
                </ul>
                <Button
                  href="https://my.nextgis.com/webgis/?show_plans&plan=premium&period=monthly"
                  size="large"
                  style={{
                    width: screens.sm ? 'auto' : '100%',
                    color: '#0070c5',
                    fontWeight: 500,
                    boxShadow: '0 3px 8px rgba(0,0,0,.12)',
                  }}
                >
                  Купить Premium
                </Button>
              </Card>
            </div>
          </div>
        </div>
      </div>
      <a
        href="https://data.nextgis.com/ru/?utm_source=nextgis&utm_medium=products&utm_campaign=nextgis-toolbox&utm_content=ru"
        target="_blank"
        rel="noreferrer"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              maxWidth: '900px',
              width: 'auto',
            }}
          >
            {/* <img
              style={{
                width: '100%',
                height: 'auto',
              }}
              src="static/data_banner_ru.png"
              alt=""
            /> */}
          </div>
        </div>
      </a>
      <pre>{`
      
      `}</pre>
    </>
  );
};

const PricingEn: React.FC = () => {
  const screens = useBreakpoint();

  return (
    <>
      <div
        style={{
          height: '100%',
        }}
      >
        <div
          style={{
            padding: '40px 24px 40px 12px',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div>
            <h1 style={{ fontSize: 34, display: 'block', margin: 0 }}>
              Pricing
            </h1>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                gap: '10px',
                flexWrap: 'wrap',
              }}
            >
              <Card
                variant="borderless"
                style={{
                  maxWidth: '550px',
                  padding: '32px 32px 32px 0px',
                  boxShadow: 'none',
                }}
                styles={{
                  body: {
                    padding: 0,
                  },
                }}
              >
                <p style={{ marginTop: 0 }}>
                  Full access to NextGIS Toolbox is part of{' '}
                  <a href="https://nextgis.com/pricing-base/">Premium</a>. You
                  can run any tool unlimited number of times if you’re on
                  Premium.{' '}
                </p>
                <p>Access details:</p>
                <ul style={{ paddingLeft: '20px' }}>
                  <li>unlimited runs</li>
                  <li>2 CPU cores</li>
                  <li>
                    16GB RAM for demanding tasks and 1GB RAM for light ones
                  </li>
                  <li>2 tasks running at a time</li>
                </ul>
                <a href="https://nextgis.com/pricing-base">
                  More about NextGIS plans
                </a>
              </Card>

              <Card
                style={{
                  position: 'relative',
                  maxWidth: '486px',
                  padding: '32px',
                  color: '#fff',
                  backgroundColor: '#0070c5',
                  boxShadow: '0 3px 8px rgba(0,0,0,.12)',
                }}
                styles={{
                  body: {
                    padding: 0,
                  },
                }}
              >
                <h3 style={{ marginTop: 0, letterSpacing: '2px' }}>PREMIUM</h3>

                <Card
                  className={
                    screens.sm ? 'pricing-sub-card' : 'pricing-sub-card__narrow'
                  }
                  style={{ height: '96px', width: '168px' }}
                  styles={{
                    body: {
                      paddingTop: '3px',
                      paddingLeft: '12px',
                    },
                  }}
                >
                  € <span className="pricing-sub-card--number ">100</span> /mo
                  <br /> or
                  <br /> € <span className="pricing-sub-card--number">
                    900
                  </span>{' '}
                  /year
                </Card>

                <ul style={{ paddingLeft: '20px', color: 'white' }}>
                  <li>
                    Unlimited runs of
                    <br /> NextGIS Toolbox
                  </li>
                  <li>
                    Cloud Web GIS at nextgis.com with unlimited layers, maps and
                    collaboration tools
                  </li>
                </ul>
                <Button
                  href="https://my.nextgis.com/webgis/?show_plans&plan=premium&period=monthly"
                  size="large"
                  style={{
                    width: screens.sm ? 'auto' : '100%',
                    color: '#0070c5',
                    fontWeight: 500,
                    boxShadow: '0 3px 8px rgba(0,0,0,.12)',
                  }}
                >
                  Go Premium!
                </Button>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <a
        href="https://data.nextgis.com/en/?utm_source=nextgis&utm_medium=products&utm_campaign=nextgis-toolbox&utm_content=en"
        target="_blank"
        rel="noreferrer"
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              maxWidth: '900px',
              width: 'auto',
            }}
          >
            {/* <img
              style={{
                width: '100%',
                height: 'auto',
              }}
              src="static/data_banner_en.png"
              alt=""
            /> */}
          </div>
        </div>
      </a>
      <pre>{`
          
          `}</pre>
    </>
  );
};

const Pricing: React.FC = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <Helmet>
        <meta property="og:title" content={t('og.pricing.title')} />
        <meta property="og:description" content={t('og.pricing.description')} />
        {/* <meta property="og:image" content="Your image URL" /> */}
      </Helmet>
      {i18n.language === 'ru' ? <PricingRu /> : <PricingEn />}
    </>
  );
};

export { Pricing };
